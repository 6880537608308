import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import './service-inner.css'
import PageHero from '../../components/pageHero'
import servicesHero from '../../images/services-hero.png'

const ArPage = () => (
  <Layout>
    <SEO title="Development and Training" />
    <PageHero {...{title: "Ambassadorship & Brand Representation", image: servicesHero}}/>
    <div className="sp-content-wrapper flex">
      <div className="sp-content">
      <h1 className="title">PASSION ABOVE ANYTHING</h1>
      <p>At the core of our values is our passion and commitment to the industry as a whole.  Our clients echo our fervour and trust us to represent their brands in national campaigns, custom tastings, individual trainings and product launches. Libation Consultants design and create unique events and campaigns that align with your brand strategy and product goals.</p>
      <p>Our ambassadors are the model of professionalism and expertise.  While building brand advocacy and loyalty, we also help you build and sustain product growth.  Offering an unparalleled understanding of the marketplace, Libation Consultants can help your brand build a trade and consumer strategy that works within the culture of the brand.  We will work with your team to create definable and measurable results for the ambassadors that drive your brands success.</p>
      <div className="service-inner-link-anchor">
      <a href="/case-studies/">View Case Studies</a>
      </div>
      </div>
    </div>
  </Layout>
)

export default ArPage
